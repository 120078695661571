import React, {useState} from "react"
import {
  Col,
  Container, Input,
  Jumbotron,
  Row
} from 'reactstrap'
import 'react-vertical-timeline-component/style.min.css';
import ReactMarkdown from "react-markdown";
import {FaExclamationTriangle} from 'react-icons/fa'
import {graphql} from "gatsby";

import 'leaflet/dist/leaflet.css'
import {TileLayer, Marker, Popup, Polyline} from 'react-leaflet'
import polyUtil from 'polyline-encoded';
import * as turf from '@turf/turf';

import moment from 'moment';

import {LangContext} from "../components/lurraldebusWrapper";
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from '../components/pageTitle'
import Map from "../components/map"

import MapMarkerParadaIcon from "../components/mapMarkerParadaIcon"

import kbusBanner from '../assets/images/banner/banner_home.png';
import OvalParadaIcon from "../assets/icon/oval-parada.svg";
import classnames from "classnames";
import FlechaOval from "../assets/icon/flecha-ciclo.svg";

const redOptions = { color: '#F69F06', smoothFactor: 0.1 }

const BloqueIncidencia = ({title, date, children}) => (
  <div className="pt-4 pb-4 buruntzaldea-incidencia-content">
    <h4 className="text-uppercase text-center font-weight-bold">{title}</h4>
    <p className="text-muted text-center">{date}</p>
    <ReactMarkdown source={children} className="text-muted pt-4"/>
  </div>
)

const IndexPage = ({data}) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = tab => {
    if (tab === null) return;
    if (activeTab !== tab) setActiveTab(tab);
  }

  const buildIncidencias = (incidencias, noIncidencias) => {
    return incidencias.length < 2 ?
      (<p className="text-muted text-center pt-3">{noIncidencias}</p>) :
      incidencias.slice(1)
        .sort((incidenciaA, incidenciaB) => {
          return moment(incidenciaA.fecha, "DD/MM/YYYY").isBefore(moment(incidenciaB.fecha, "DD/MM/YYYY")) ? 1 : -1;
        }).map((incidencia) =>
          <BloqueIncidencia key={incidencia.id} title={incidencia.titulo} date={incidencia.fecha} children={incidencia.descripcion} />
        )
  }

  /*
  const geometriasRutas = {
    'KBus1': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'KBus2': [
      "qr`gGb_mQHQgAkEaIeHgA_HfCaIhBwG}@{FeByEaEiCeAoFsFuFPgPuBiL{A}BwK_O`@uSyAoDaDu@cDl@{JsEaF}BwEjDwG|@sGyFgCgFuR~AoBHm@@sAzHwIoCo@g@gA_@uJpW]v@yERmD_Oe@eC{DwX{@oPa@}JeA{@qFdCoK`IMrAuD`Cy@Q_@uAvCoDjEmH{F_AsG_FoAD}B}IsEwB}@`FoAG{@zF",
      "y`hgGz_fQq@jFnQMJ~AiD~WIZ|ClHjJgG~BkG|CuAfATrGkCaBcJmAl@dAlFgBr@ZzBvAdEMvCjDjA_AqO~@]bDk@vAfP\\pDtY~JfDnA`FjB`FzBnByHzFmBpOWpEvGvEvDfG_AvEkD`F|BzJrEbDm@`Dt@xAnDa@tSlKrNdBhCtBhLG|PhF~EdAnF`EhCvAxDlAjGkBfHgC`IfA~G`IdHfAjEIP"
    ],
    'KBus3': [
      "gk_gG~vcQc@@aBeCqIuDuGbBkRjR_FfD_Ch@yFwC{Fk@}NpHsPp@e@Vmc@nHsAExDuQCwA_FpReXlG~F~PUVr@fAlCtMRt@`B{C`DqBfF_KHkAfDgHjGyC`GoC",
      "uacgGz{dQaGnCkGxCgDfHGd@mEnJyGvGe@\\`CmC`FiE}HxHgD_NuB_I{BuFJcBhUkF`DXlc@oHd@WrPq@|NqHzFj@xFvC~Bi@~EgDjRkRtGcBpItD`BdCb@A"
    ],
  }

  const lineasData = data.lineas.edges.map((linea) => {

    const rutasData = linea.node.rutas.map((ruta, rutaIndex) => {
      const rutaPoints = ruta.paradas.map((parada) => {
        return [parseFloat(parada.coord.lat), parseFloat(parada.coord.lon)];
      });

      const rutaMarkers = ruta.paradas.map((parada, paradaIndex) => {
        const key = `${linea.node.nombre}-${rutaIndex}-${paradaIndex}`
        return (
          <Marker position={[parada.coord.lat, parada.coord.lon]} icon={MapMarkerParadaIcon()} key={key}>
            <Popup>
              <span className="font-weight-bold">{parada.nombre}</span>
              <br/>
              <span>{linea.node.nombre}</span>
            </Popup>
          </Marker>
        )
      });

      return {
        points: rutaPoints,
        markers: rutaMarkers
      }
    })

    return {
      nombre: linea.node.nombre,
      markers: rutasData[0].markers.concat(rutasData[1].markers),
      points: rutasData[0].points.concat(rutasData[1].points),
      polylines: geometriasRutas[linea.node.label]
    }
  });

  const nuestrasLineas = lineasData.map((linea, index) => {
    const features = turf.points(linea.points);
    const _center = turf.center(features);

    return (
        <Row key={index} className="pt-3 mb-3 border-bottom pb-5">
          <Col xs={12} className="pt-3 mb-3">
            <h5 className="text-center font-weight-bold pb-3">{linea.nombre}</h5>
          </Col>
          <Col xs={12} style={{height: '500px'}}>
            <Map centroMapa={_center.geometry.coordinates} zoomMapa={13} styleMapa={{height: '100%'}}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {linea.markers}
              {linea.polylines.map((polyline, index) => {
                const positions = polyUtil.decode(polyline);
                return <Polyline key={index} pathOptions={redOptions} positions={positions} />
              })}
            </Map>
          </Col>
        </Row>
    )
  })
  */

  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'home', 'titulo')} />
          <Container className="px-0 pt-0 mt-0">
            <Jumbotron className="w-100 overflow-hidden">
              <img src={kbusBanner} alt="buruntzaldeabus" className="kbus-home-banner"/>
            </Jumbotron>
          </Container>
          <Container className="pt-5 mb-5">
            <Row>
              <Col xs={{size: 12, offset:0}} md={{size:8, offset:2}} lg={{size:6, offset:3}}>
                <h4 className="text-orange text-center font-weight-bold border-bottom pb-3"><FaExclamationTriangle className="mb-1 mr-2"/><u>{context.translate(data, 'incidencias', 'titulo')}</u></h4>
                {buildIncidencias(context.translate(data, 'incidencias', 'incidencias'), context.translate(data, 'incidencias', 'noIncidencias'))}
              </Col>
            </Row>
          </Container>

          <Container className="pt-3 mb-5">
            <PageTitle title={context.translate(data, 'home', 'nuestrasLineas')}/>
            <Row>
              <Col xs="12" md={{size: 10, offset:1}} lg={{size:8, offset:2}}>
                <div className="d-flex flex-row justify-content-center align-items-center pt-3 pb-4 px-5">
                  <Input className="text-capitalize" type="select" name="linea" id="linea" placeholder={"Selecciona una Línea"} onChange={(e) => {toggle(e.target.value)}}>
                    {context.translate(data, 'lineasyhorarios', 'lineas').map(linea =>
                      <option key={linea.identificador} value={linea.identificador} className="text-capitalize">{linea.linea}</option>
                    )}
                  </Input>
                </div>
              </Col>
            </Row>

            <Row>
              {context.translate(data, 'lineasyhorarios', 'lineas').map((linea, index) => {
                  const _filtered = data.lineas.edges.filter ((_linea) => {
                    return _linea.node.label == linea.linea.split(' ')[0];
                  });

                  if (_filtered.length === 0) {
                    return null;
                  }

                  const rutas = _filtered[0].node.rutas;

                  const paradasIda = rutas[0].paradas.map((parada, index) => (
                    <div className="d-flex flex-row justify-content-end py-2" key={index}>
                      <span className="mx-2">{parada.nombre}</span>
                      <img src={OvalParadaIcon} alt={parada.nombre}/>
                    </div>
                  ))

                  const paradasVuelta = rutas[1].paradas.reverse().map((parada, index) => (
                    <div className="d-flex flex-row-reverse justify-content-end py-2" key={index}>
                      <span className="mx-2">{parada.nombre}</span>
                      <img src={OvalParadaIcon} alt={parada.nombre}/>
                    </div>
                  ))

                  return (
                    <Col
                      xs="12" md={{size: 10, offset: 1}} lg={{size: 8, offset: 2}}
                      className={classnames({'d-none d-lg-none': activeTab != linea.identificador, 'd-none d-lg-block': activeTab == linea.identificador, 'mt-4': true})}
                      key={linea.identificador}
                    >
                      <h4 className="text-center text-muted pb-4">{rutas[0].nombre.split(">")[0].trim()}</h4>
                      <div className="d-flex flex-row justify-content-center">
                        <div className="pt-0 pb-5 d-flex flex-column justify-content-between">
                          <div className="d-flex flex-row justify-content-end">
                            <img className="kbus-paradas-ciclo-flecha" src={FlechaOval} alt="ida"/>
                          </div>
                          <div className="d-flex flex-grow-1 flex-column justify-content-around kbus-paradas-ida">
                            {paradasIda}
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <div className="kbus-paradas-ciclo-top"></div>
                          <div className="kbus-paradas-ciclo-mid flex-grow-1"></div>
                          <div className="kbus-paradas-ciclo-bottom"></div>
                        </div>
                        <div className="pt-5 pb-0 d-flex flex-column justify-content-between">
                          <div className="flex-grow-1 d-flex flex-column justify-content-around kbus-paradas-vuelta">
                            {paradasVuelta}
                          </div>
                          <div className="d-flex flex-row justify-content-start">
                            <img className="kbus-paradas-ciclo-flecha vuelta" src={FlechaOval} alt="vuelta"/>
                          </div>
                        </div>
                      </div>
                      <h4 className="text-center text-muted pt-4">{rutas[1].nombre.split(">")[0].trim()}</h4>
                    </Col>
                  )
                }
              )}

              {context.translate(data, 'lineasyhorarios', 'lineas').map((linea, index) => {
                  const _filtered = data.lineas.edges.filter ((_linea) => {
                    return _linea.node.label == linea.linea.split(' ')[0];
                  });

                  if (_filtered.length === 0) {
                    return null;
                  }

                  const rutas = _filtered[0].node.rutas;

                  const paradasIda = rutas[0].paradas.map((parada, index) => (
                    <div className="d-flex flex-row-reverse justify-content-end py-2" key={index}>
                      <span className="mx-2">{parada.nombre}</span>
                      <img src={OvalParadaIcon} alt={parada.nombre}/>
                    </div>
                  ))

                  const paradasVuelta = rutas[1].paradas.reverse().map((parada, index) => (
                    <div className="d-flex flex-row-reverse justify-content-end py-2" key={index}>
                      <span className="mx-2">{parada.nombre}</span>
                      <img src={OvalParadaIcon} alt={parada.nombre}/>
                    </div>
                  ))

                  return (
                    <Col
                      xs="12" md={{size: 10, offset: 1}} lg={{size: 8, offset: 2}}
                      className={classnames({'d-none d-lg-none': activeTab != linea.identificador, 'd-block d-lg-none': activeTab == linea.identificador, 'mt-4': true})}
                      key={linea.identificador}
                    >
                      <h4 className="text-center text-muted pb-4">{rutas[0].nombre.split(">")[0].trim()}</h4>
                      <div className="d-flex flex-row justify-content-start ml-4">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="kbus-paradas-ciclo-mobile flex-grow-1"></div>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div className="d-flex flex-grow-1 flex-column justify-content-around kbus-paradas-mobile">
                            {paradasIda}
                          </div>
                        </div>
                      </div>
                      <h4 className="text-center text-muted pt-4">{rutas[1].nombre.split(">")[0].trim()}</h4>

                      <div className="py-5"></div>

                      <h4 className="text-center text-muted pb-4">{rutas[1].nombre.split(">")[0].trim()}</h4>
                      <div className="d-flex flex-row justify-content-start ml-4">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="kbus-paradas-ciclo-mobile flex-grow-1"></div>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div className="d-flex flex-grow-1 flex-column justify-content-around kbus-paradas-mobile">
                            {paradasVuelta}
                          </div>
                        </div>
                      </div>
                      <h4 className="text-center text-muted pt-4">{rutas[0].nombre.split(">")[0].trim()}</h4>
                    </Col>
                  )
                }
              )}
            </Row>
          </Container>

        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default IndexPage

export const pageQuery = graphql`  
  query {
    home: strapiHome {
      titulo
      nuestrasLineas
    }
    home_eus: strapiHomeEus {
      titulo
      nuestrasLineas
    }
    incidencias: strapiIncidencias {
      noIncidencias
      titulo
      incidencias {
        id
        titulo
        fecha
        descripcion
      }
    }
    incidencias_eus: strapiIncidenciasEus {
      noIncidencias
      titulo
      incidencias {
        id
        titulo
        fecha
        descripcion
      }
    },
    lineasyhorarios: strapiLineasYHorarios {
      horarios
      titulo
      itinerarios
      paradas
      lineas {
        linea
        identificador
        paradas {
          publicURL
        }
        horarios {
            horarios {
              titulo
              tablas {
                origen
                destino
                paradas
                tiempos {
                  special
                  values
                }
              }
            }
          }
      }
    }
    lineasyhorarios_eus: strapiLineasYHorariosEus {
      horarios
      titulo
      itinerarios
      paradas
      lineas {
        linea
        identificador
        paradas {
          publicURL
        }
        horarios {
            horarios {
              titulo
              tablas {
                origen
                destino
                paradas
                tiempos {
                  special
                  values
                }
              }
            }
          }
      }
    }
    lineas: allStrapiLineas {
      edges {
        node {
          label
          nombre
          rutas {
            direccion
            nombre
            paradas {
              nombre
              label
              coord {
                lat
                lon
              }
            }
          }
        }
      }
    }
  }
`
